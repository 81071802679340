import { createStore } from 'vuex'

export default createStore({
  state: {
    userEmail: null
  },
  mutations: {
    updateEmail (state, email) {
      state.userEmail = email
    }
  },
  actions: {
  },
  modules: {
  }
})
